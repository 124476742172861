body {
    margin: 0;
    background-color: #f8f9fb;
}

#loading_logo {
    display: flex;
    width: 250px;
    margin: 25vh auto;
    -webkit-animation: breathing 2000ms ease-out infinite normal;
    animation: breathing 2000ms ease-out infinite normal;
}

@-webkit-keyframes breathing {
    0% {
        opacity: 0.52;
    }

    50% {
        opacity: 0.16;
    }

    100% {
        opacity: 0.52;
    }
}

@keyframes breathing {
    0% {
        opacity: 0.52;
    }

    50% {
        opacity: 0.16;
    }

    100% {
        opacity: 0.52;
    }
}
